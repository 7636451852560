//Оформление шоколадными шариками
import boss from "../img/item/Оформление тортов/Шоколадными шариками/Boss.avif";
import gymnast from "../img/item/Оформление тортов/Шоколадными шариками/Гимнастка16.avif";
import girl_with_ball from "../img/item/Оформление тортов/Шоколадными шариками/Девушка с шарами.avif";
import bear_candle from "../img/item/Оформление тортов/Шоколадными шариками/Мишка со свечкой.avif";
import detonation from "../img/item/Оформление тортов/Шоколадными шариками/Взрыв.avif";



export const ballDecor = [
  {
    name: `Оформление шоколадными шариками`,
    descDecor: 'поз. 1',
    img: boss,
  },
  {
    name: `Оформление шоколадными шариками`,
    descDecor: 'поз. 2',
    img: gymnast,
  },
  {
    name: `Оформление шоколадными шариками`,
    descDecor: 'поз. 3',
    img: girl_with_ball,
  },
  {
    name: `Оформление шоколадными шариками`,
    descDecor: 'поз. 4',
    img: bear_candle,
  },
  {
    name: `Оформление шоколадными шариками`,
    descDecor: 'поз. 5',
    img: detonation,
  },
];