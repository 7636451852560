import {
  exotic,
  roshe,
  pina,
  med_chernosliv,
  karamel_yabloko,
  kardamon,
  ezhevika,
  bob_tonka,
  oblepiha_med_bob_tonka,
  explosion,
  kofe,
  arachis_explosion,
} from "./img_import";

export const mussList = {
  meta: {
    title: "ДжоКонди | Муссовые",
    description: "Муссовые торты",
    keywords:
      "экзотик, роше, пина, колада, мёд, йогурт, чернослив, шоколад, ежевика, брауни, боб-тонка, облепиха",
  },
  productList: [
    {
      id: "mt1",
      groupProduct: "Муссовый торт",
      nameProduct: '"Экзотик"',
      img: exotic,
      desc: [
        "Желе манго маракуйя",
        "Кокосовый бисквит",
        "Хрустящий слой",
        "Ванильный мусс",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 4900,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
      hit: true,
    },
    {
      id: "mt2",
      groupProduct: "Муссовый торт",
      nameProduct: '"Шоколадное роше"',
      img: roshe,
      desc: [
        "Шоколадный взбитый ганаш",
        "Шоколадный мусс",
        "Шоколадный бисквит",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 3724,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mt3",
      groupProduct: "Муссовый торт",
      nameProduct: '"Пина Колада (с ромом)"',
      img: pina,
      desc: [
        "Кокосовый бисквит",
        "Хрустящий слой",
        "Ананасовый компот",
        "Ганаш Пина колада",
        "Кокосовый мусс",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 5900,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mt4",
      groupProduct: "Муссовый торт",
      nameProduct: '"Пина Колада"',
      img: pina,
      desc: [
        "Кокосовый бисквит",
        "Хрустящий слой",
        "Ананасовый компот",
        "Ганаш Пина колада",
        "Кокосовый мусс",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 5750,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mt5",
      groupProduct: "Муссовый торт",
      nameProduct: '"Мёд-Йогурт-Чернослив"',
      img: med_chernosliv,
      desc: [
        "Медовый бисквит с грецким орехом",
        "Компотэ черника",
        "Чернослив",
        "Медово-йогуртовый мусс с рикоттой",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 3500,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mt6",
      groupProduct: "Муссовый торт",
      nameProduct: '"Карамельное яблоко"',
      img: karamel_yabloko,
      desc: [
        "Яблочный компот",
        "Карамельный ганаш",
        "Карамельный бисквит с бобами тонка",
        "Хрустящий карамельный слой",
        "Сливочная карамель",
        "Карамельный мусс",
        "Карамельная глазурь",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 5300,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
      hit: true,
    },
    {
      id: "mt7",
      groupProduct: "Муссовый торт",
      nameProduct: '"Клубничный взрыв"',
      img: explosion,
      desc: [
        "Ванильный бисквит",
        "Хрустящий слой",
        "Компот клубника",
        "Клубнично-ванильный мусс",
        "Ягодная глазурь",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 4700,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
      hit: true,
    },
    {
      id: "mt8",
      groupProduct: "Муссовый торт",
      nameProduct: '"Кофе шоколад"',
      img: kofe,
      desc: [
        "Шоколадный бисквит с орехом пекан",
        "Шоколадно-кофейный мусс",
        "Шоколадная глазурь",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 4300,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
      hit: true,
    },
    {
      id: "mt9",
      groupProduct: "Муссовый торт",
      nameProduct: '"Арахисовый взрыв"',
      img: arachis_explosion,
      desc: [
        "Шоколадный бисквит",
        "Арахисовый штрейзель",
        "Хрустящий слой сливочная карамель",
        "Взбитый шоколадный ганаш с арахисом",
        "Шоколадный мусс",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 4300,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
      hit: true,
    },
    {
      id: "mt10",
      groupProduct: "Муссовый торт",
      nameProduct: '"Груша-Карамель-Кардамон"',
      img: kardamon,
      desc: [
        "Брауни с кедровым орехом",
        "Сливочная карамель",
        "Компотэ Груша",
        "Намелака Кардамон",
        "Карамельный мусс",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 3724,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
      hit: true,
    },
    {
      id: "mt11",
      groupProduct: "Муссовый торт",
      nameProduct: '"Ежевика Брауни"',
      img: ezhevika,
      desc: ["Брауни", "Ганаш ежевика", "Мусс ежевика"],
      anot: ["Вес торта 1400 гр."],
      price: 4300,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mt12",
      groupProduct: "Муссовый торт",
      nameProduct: '"Груша Боб Тонка"',
      img: bob_tonka,
      desc: [
        "Морковный бисквит",
        "Грушевый компот с бобами тонка",
        "Грушевое кремю",
        "Сырно-сливочный мусс с бобами тонка",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 3200,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mt13",
      groupProduct: "Муссовый торт",
      nameProduct: '"Облепиха-Мёд-Боб Тонка"',
      img: oblepiha_med_bob_tonka,
      desc: [
        "Брауни с карамелизированным шоколадом и орехом пекан",
        "Конфи облепиха",
        "Сырно-медовый мусс",
      ],
      anot: ["Вес торта 1400 гр."],
      price: 3350,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
  ],
  decorList: [
    { dataDecor: "Базовое оформление", priceDecor: 0 },
    { dataDecor: "Цветами из вафельной бумаги", priceDecor: 500 },
    { dataDecor: "Сложный декор", priceDecor: 1000 },
    { dataDecor: "Свежими ягодами", priceDecor: 200 },
  ],
};
