//Оформление пряниками
import cake1 from "../img/item/Оформление тортов/Пряниками/cake1.avif";
import cake2 from "../img/item/Оформление тортов/Пряниками/cake2.avif";
import cake3 from "../img/item/Оформление тортов/Пряниками/cake3.avif";
import cake4 from "../img/item/Оформление тортов/Пряниками/cake4.avif";
import cake5 from "../img/item/Оформление тортов/Пряниками/cake5.avif";
import cake6 from "../img/item/Оформление тортов/Пряниками/cake6.avif";


export const cakeDecor = [
  {
    name: `Оформление торта фигурками из пряника"`,
    descDecor: 'поз. 1',
    img: cake1,
  },
  {
    name: `Оформление торта фигурками из пряника"`,
    descDecor: 'поз. 2',
    img: cake2,
  },
  {
    name: `Оформление торта фигурками из пряника"`,
    descDecor: 'поз. 3',
    img: cake3,
  },
  {
    name: `Оформление торта фигурками из пряника"`,
    descDecor: 'поз. 4',
    img: cake4,
  },
  {
    name: `Оформление торта фигурками из пряника"`,
    descDecor: 'поз. 5',
    img: cake5,
  },
  {
    name: `Оформление торта фигурками из пряника"`,
    descDecor: 'поз. 6',
    img: cake6,
  },
];