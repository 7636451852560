import {
  rafaello_candy,
  oreo_candy,
  basilik_candy,
  halva_candy,
  fistashka_candy,
  funduk_candy,
  smorodina_candy,
  limon_candy,
  finik_candy,
  drunk_cherry,
  oblepiha_appelsin,
} from "./img_import";

export const candyList = {
  meta: {
    title: "ДжоКонди | Конфеты",
    description: "Конфеты",
    keywords:
      "рафаэлло, клубника, орео, фисташка, фундучное, смородина, лимон, мята, финик, вишня, апельсин, пьяная вишня, облепиха",
  },
  productList: [
    {
      id: "candy1",
      groupProduct: "Конфета",
      nameProduct: '"Рафаэлло"',
      img: rafaello_candy,
      price: 100,
      unit: "шт.",
      minCount: 5, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "candy2",
      groupProduct: "Конфета",
      nameProduct: '"Клубника-Базелик"',
      img: basilik_candy,
      price: 200,
      unit: "шт.",
      minCount: 5, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "candy3",
      groupProduct: "Конфета",
      nameProduct: '"Орео"',
      img: oreo_candy,
      price: 170,
      unit: "шт.",
      minCount: 5, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "candy4",
      groupProduct: "Конфета",
      nameProduct: '"Шоколадная халва"',
      img: halva_candy,
      price: 100,
      unit: "шт.",
      minCount: 5, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "candy5",
      groupProduct: "Конфета",
      nameProduct: '"Фисташка"',
      img: fistashka_candy,
      price: 200,
      unit: "шт.",
      minCount: 5, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "candy6",
      groupProduct: "Конфета",
      nameProduct: '"Фундучное пралине"',
      img: funduk_candy,
      price: 110,
      unit: "шт.",
      minCount: 5, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "candy7",
      groupProduct: "Конфета",
      nameProduct: '"Чёрная смородина"',
      img: smorodina_candy,
      price: 155,
      unit: "шт.",
      minCount: 5, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "candy8",
      groupProduct: "Конфета",
      nameProduct: '"Лимон-Мята"',
      img: limon_candy,
      price: 130,
      unit: "шт.",
      minCount: 5, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "candy9",
      groupProduct: "Конфета",
      nameProduct: '"Финик-Орех"',
      img: finik_candy,
      price: 130,
      unit: "шт.",
      minCount: 5, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "candy10",
      groupProduct: "Конфета",
      nameProduct: '"Пьяная вишня"',
      img: drunk_cherry,
      price: 155,
      unit: "шт.",
      minCount: 5, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "candy11",
      groupProduct: "Конфета",
      nameProduct: '"Облепиха-Апельсин"',
      img: oblepiha_appelsin,
      price: 140,
      unit: "шт.",
      minCount: 5, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
  ],
};
