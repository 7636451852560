//Рисунком в акварельной технике
import aquarelle1 from "../img/item/Оформление тортов/Акварель/aquarelle1.avif";
import aquarelle2 from "../img/item/Оформление тортов/Акварель/aquarelle2.avif";
import aquarelle3 from "../img/item/Оформление тортов/Акварель/aquarelle3.avif";
import aquarelle4 from "../img/item/Оформление тортов/Акварель/aquarelle4.avif";
import aquarelle5 from "../img/item/Оформление тортов/Акварель/aquarelle5.avif";
import aquarelle6 from "../img/item/Оформление тортов/Акварель/aquarelle6.avif";
import aquarelle7 from "../img/item/Оформление тортов/Акварель/aquarelle7.avif";
import aquarelle8 from "../img/item/Оформление тортов/Акварель/aquarelle8.avif";
import aquarelle9 from "../img/item/Оформление тортов/Акварель/aquarelle9.avif";
import aquarelle10 from "../img/item/Оформление тортов/Акварель/aquarelle10.avif";
import aquarelle11 from "../img/item/Оформление тортов/Акварель/aquarelle11.avif";
import aquarelle12 from "../img/item/Оформление тортов/Акварель/aquarelle12.avif";
import aquarelle13 from "../img/item/Оформление тортов/Акварель/aquarelle13.avif";


export const aquarelleDecor = [
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 1',
    img: aquarelle1,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 2',
    img: aquarelle2,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 3',
    img: aquarelle3,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 4',
    img: aquarelle4,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 5',
    img: aquarelle5,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 6',
    img: aquarelle6,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 7',
    img: aquarelle7,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 8',
    img: aquarelle8,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 9',
    img: aquarelle9,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 10',
    img: aquarelle10,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 11',
    img: aquarelle11,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 12',
    img: aquarelle12,
  },
  {
    name: `Рисунок в акварельной технике`,
    descDecor: 'поз. 13',
    img: aquarelle13,
  },
];