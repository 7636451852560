//Оформление цветами из вафельной бумаги
import flowers1 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы1.avif";
import flowers2 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы2.avif";
import flowers3 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы3.avif";
import flowers4 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы4.avif";
import flowers5 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы5.avif";
import flowers6 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы6.avif";
import flowers7 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы7.avif";
import flowers8 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы8.avif";
import flowers9 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы9.avif";
import flowers10 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы10.avif";
import flowers11 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы11.avif";
import flowers12 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы12.avif";
import flowers13 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы13.avif";
import flowers14 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы14.avif";
import flowers15 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы15.avif";
import flowers16 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы16.avif";
import flowers17 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы17.avif";
import flowers18 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы18.avif";
import flowers19 from "../img/item/Оформление тортов/Цветы из вафельной бумаги/Цветы19.avif";


export const flowersDecor = [
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 1',
    img: flowers1,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 2',
    img: flowers2,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 3',
    img: flowers3,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 4',
    img: flowers4,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 5',
    img: flowers5,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 6',
    img: flowers6,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 7',
    img: flowers7,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 8',
    img: flowers8,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 9',
    img: flowers9,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 10',
    img: flowers10,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 11',
    img: flowers11,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 12',
    img: flowers12,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 13',
    img: flowers13,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 14',
    img: flowers14,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 15',
    img: flowers15,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 16',
    img: flowers16,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 17',
    img: flowers17,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 18',
    img: flowers18,
  },
  {
    name: `Оформление цветами из вафельной бумаги`,
    descDecor: 'поз. 19',
    img: flowers19,
  },
];