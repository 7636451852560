import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";

import "./styles/reset.css";
import "./styles/main.css";

import { Header } from "./Header";

import {
  candyList,
  biscuitList,
  mussList,
  bentoList,
  multiList,
  tartsList,
  mussCakesList,
  makaronsList,
  zefirList,
  ponchikList,
  basicDecor,
  bentoDecor,
  isomaltDecor,
  ballDecor,
  flowersDecor,
  paintingDecor,
  sugarDecor,
  hardDecor,
  cakeDecor,
  handDecor,
  aquarelleDecor,
  berryDecor,
} from "./data_import";

import { actionList } from "./dataAction";

const MainPage = lazy(() => import("./Pages/MainPage"));
const ProductPage = lazy(() => import("./Pages/ProductPage"));
const DecorPage = lazy(() => import("./Pages/Decor/DecorPage"));
const BasketPage = lazy(() => import("./Pages/BasketPage"));
const DeliveryPage = lazy(() => import("./Pages/DeliveryPage"));
const AgreementPage = lazy(() => import("./Pages/AgreementPage"));
const DecorList = lazy(() => import("./Pages/Decor/DecorList"));
const NotFound = lazy(() => import("./Pages/NotFound"));

export default function App() {
  return (
    <div className="App">
      <Header />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/biscuit" element={<ProductPage productList={biscuitList} />} />
          <Route path="/muss" element={<ProductPage productList={mussList} />} />
          <Route path="/bento" element={<ProductPage productList={bentoList} />} />
          <Route path="/multi" element={<ProductPage productList={multiList} />} />
          <Route path="/tarts" element={<ProductPage productList={tartsList} />} />
          <Route path="/cakes" element={<ProductPage productList={mussCakesList} />} />
          <Route path="/makarons" element={<ProductPage productList={makaronsList} />} />
          <Route path="/zefir" element={<ProductPage productList={zefirList} />} />
          <Route path="/ponchiki" element={<ProductPage productList={ponchikList} />} />
          <Route path="/candy" element={<ProductPage productList={candyList} />} />
          <Route path="/decor" element={<DecorPage />} />
          <Route path="/decor/basic" element={<DecorList decorItem={basicDecor} />} />
          <Route path="/decor/bento" element={<DecorList decorItem={bentoDecor} />} />
          <Route path="/decor/isomalt" element={<DecorList decorItem={isomaltDecor} />} />
          <Route path="/decor/ball" element={<DecorList decorItem={ballDecor} />} />
          <Route path="/decor/flowers" element={<DecorList decorItem={flowersDecor} />} />
          <Route path="/decor/painting" element={<DecorList decorItem={paintingDecor} />} />
          <Route path="/decor/sugar" element={<DecorList decorItem={sugarDecor} />} />
          <Route path="/decor/hard" element={<DecorList decorItem={hardDecor} />} />
          <Route path="/decor/aquarelle" element={<DecorList decorItem={aquarelleDecor} />} />
          <Route path="/decor/berry" element={<DecorList decorItem={berryDecor} />} />
          <Route path="/decor/cake" element={<DecorList decorItem={cakeDecor} />} />
          <Route path="/decor/hand" element={<DecorList decorItem={handDecor} />} />
          <Route path="/delivery" element={<DeliveryPage />} />
          <Route path="/basket" element={<BasketPage />} />
          <Route path="/agreement" element={<AgreementPage />} />
          <Route path="*" element={<NotFound />} />
          {/* <Route path="/action" element={<ProductPage productList={actionList} />} /> */}
        </Routes>
      </Suspense>
    </div>
  );
}
