export const dataMenu = [
  { id: 1, level:2, href: "/", nameMenu: "Главная страница" },
  { id: 2, href: "/biscuit", nameMenu: "Бисквитные" },
  { id: 3, href: "/muss", nameMenu: "Муссовые" },
  { id: 4, href: "/bento", nameMenu: "Бенто" },
  { id: 5, href: "/multi", nameMenu: "Многоярусные" },
  { id: 6, level: 1, href: "/tarts", nameMenu: "Тарты" },
  { id: 7, level: 1, href: "/cakes", nameMenu: "Муссовые пироженные" },
  { id: 8, level: 1, href: "/makarons", nameMenu: "Макаронсы" },
  { id: 9, level: 1, href: "/zefir", nameMenu: "Зефир и Безе" },
  { id: 10, level: 1, href: '/candy', nameMenu: "Конфеты" },
  { id: 11, level: 1, href: "/ponchiki", nameMenu: "Тортопончики" },
  { id: 12, level: 2, href: "/decor", nameMenu: "Оформление тортов" },
  { id: 13, level: 2, href: "/delivery", nameMenu: "Доставка" },
  // { id: 14, level: 2, href: "/action", nameMenu: "Новогоднее предложение", action: true },
];

export const menu = [
  {
    id: 0,
    level: 1,
    href: "/tort",
    nameMenu: "Торты",
    subMenu: [
      dataMenu[1],
      dataMenu[2],
      dataMenu[3],
      dataMenu[4],
    ],
  },
  dataMenu[5],
  dataMenu[6],
  dataMenu[7],
  dataMenu[8],
  dataMenu[9],
  dataMenu[10],

  dataMenu[0],
  dataMenu[11],
  // dataMenu[13], // Акционная страница
  dataMenu[12],
];
