//Оформление свежими ягодами
import berry1 from "../img/item/Оформление тортов/Ягодами/berry1.avif";
import berry2 from "../img/item/Оформление тортов/Ягодами/berry2.avif";
import berry3 from "../img/item/Оформление тортов/Ягодами/berry3.avif";
import berry4 from "../img/item/Оформление тортов/Ягодами/berry4.avif";
import berry5 from "../img/item/Оформление тортов/Ягодами/berry5.avif";
import berry6 from "../img/item/Оформление тортов/Ягодами/berry6.avif";
import berry7 from "../img/item/Оформление тортов/Ягодами/berry7.avif";
import berry8 from "../img/item/Оформление тортов/Ягодами/berry8.avif";
import berry9 from "../img/item/Оформление тортов/Ягодами/berry9.avif";
import berry10 from "../img/item/Оформление тортов/Ягодами/berry10.avif";
import berry11 from "../img/item/Оформление тортов/Ягодами/berry11.avif";


export const berryDecor = [
  {
    name: `Оформление свежими ягодами`,
    descDecor: 'поз. 1',
    img: berry1,
  },
  {
    name: `Оформление свежими ягодами`,
    descDecor: 'поз. 2',
    img: berry2,
  },
  {
    name: `Оформление свежими ягодами`,
    descDecor: 'поз. 3',
    img: berry3,
  },
  {
    name: `Оформление свежими ягодами`,
    descDecor: 'поз. 4',
    img: berry4,
  },
  {
    name: `Оформление свежими ягодами`,
    descDecor: 'поз. 5',
    img: berry5,
  },
  {
    name: `Оформление свежими ягодами`,
    descDecor: 'поз. 6',
    img: berry6,
  },
  {
    name: `Оформление свежими ягодами`,
    descDecor: 'поз. 7',
    img: berry7,
  },
  {
    name: `Оформление свежими ягодами`,
    descDecor: 'поз. 8',
    img: berry8,
  },
  {
    name: `Оформление свежими ягодами`,
    descDecor: 'поз. 9',
    img: berry9,
  },
  {
    name: `Оформление свежими ягодами`,
    descDecor: 'поз. 10',
    img: berry10,
  },
  {
    name: `Оформление свежими ягодами`,
    descDecor: 'поз. 11',
    img: berry11,
  },
];