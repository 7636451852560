import {
  multi,
  wedding1,
  jubilee1,
  wedding2,
  jubilee2,
  jubilee3,
  wedding4,
  children,
  provans,
  illusion,
  sea,
} from "./img_import";

export const multiList = {
  meta: {
    title: "ДжоКонди | Многоярусные",
    description: "Многоярусные торты",
    keywords: "свадьба, юбилей, многоярусный, день рождения",
  },
  productList: [
    {
      id: "mult1",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Многоярусный"',
      img: multi,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult2",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Свадебный"',
      img: wedding1,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult3",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Детские сны"',
      img: children,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult4",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Прованс"',
      img: provans,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult5",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Иллюзия"',
      img: illusion,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult6",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Морской"',
      img: sea,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult7",
      groupProduct: "Торт бисквитный",
      nameProduct: '"На юбилей"',
      img: jubilee1,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult8",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Свадебный"',
      img: wedding2,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult9",
      groupProduct: "Торт муссовый",
      nameProduct: '"На юбилей"',
      img: jubilee2,
      price: 2500,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult11",
      groupProduct: "Торт бисквитный",
      nameProduct: '"На юбилей"',
      img: jubilee3,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult12",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Свадебный"',
      img: wedding4,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
  ],
};
