import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

import { dataMenu } from "../dataMenu.js";

const DroopMenu = ({ openMenu }) => {

  return (
    <ul className={openMenu ? "sideMenu menu--open" : "sideMenu"}>
      {dataMenu.map((item) => (
        <li key={item.id} className = {item.action ? "sideMenu__link-block sideMenu__action" : "sideMenu__link-block"}>
          <Link to={item.href} className="sideMenu__link">
            {item.nameMenu}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default DroopMenu;
