//Оформление леденцами из изомальта
import two_year from "../img/item/Оформление тортов/Леденцами/2 года.avif";
import brawl_stars from "../img/item/Оформление тортов/Леденцами/Brawl Stars.avif";
import NFC from "../img/item/Оформление тортов/Леденцами/NFC.avif";
import Ganna10 from "../img/item/Оформление тортов/Леденцами/Жанночка 10.avif";
import kat from "../img/item/Оформление тортов/Леденцами/Кот.avif";
import multicoloured from "../img/item/Оформление тортов/Леденцами/Разноцветный 5.avif";
import rome7 from "../img/item/Оформление тортов/Леденцами/Роме 7.avif";
import superheros from "../img/item/Оформление тортов/Леденцами/Супергерои.avif";
import traktor from "../img/item/Оформление тортов/Леденцами/Трактор.avif";
import black_kat from "../img/item/Оформление тортов/Леденцами/Черный кот.avif";


export const isomaltDecor = [
  {
    name: `Оформление леденцами`,
    descDecor: 'поз. 1',
    img: two_year,
  },
  {
    name: `Оформление леденцами`,
    descDecor: 'поз. 2',
    img: brawl_stars,
  },
  {
    name: `Оформление леденцами`,
    descDecor: 'поз. 3',
    img: NFC,
  },
  {
    name: `Оформление леденцами`,
    descDecor: 'поз. 4',
    img: Ganna10,
  },
  {
    name: `Оформление леденцами`,
    descDecor: 'поз. 5',
    img: kat,
  },
  {
    name: `Оформление леденцами`,
    descDecor: 'поз. 6',
    img: multicoloured,
  },
  {
    name: `Оформление леденцами`,
    descDecor: 'поз. 7',
    img: rome7,
  },
  {
    name: `Оформление леденцами`,
    descDecor: 'поз. 8',
    img: superheros,
  },
  {
    name: `Оформление леденцами`,
    descDecor: 'поз. 9',
    img: traktor,
  },
  {
    name: `Оформление леденцами`,
    descDecor: 'поз. 10',
    img: black_kat,
  },
  
];