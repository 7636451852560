import React from "react";

import { NavBarItems } from "../NavBarItems";

import "./style.css";

export const NavBar = ({ menu, navClass }) => {

  return (
    <nav className={navClass}>
      {menu.map((item) => (
        <NavBarItems key={item.id} item={item} />
      ))}
    </nav>
  );
};
