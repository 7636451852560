//Роспись торта
import painting1 from "../img/item/Оформление тортов/Роспись/painting1.avif";
import painting2 from "../img/item/Оформление тортов/Роспись/painting2.avif";
import painting3 from "../img/item/Оформление тортов/Роспись/painting3.avif";
import painting4 from "../img/item/Оформление тортов/Роспись/painting4.avif";
import painting5 from "../img/item/Оформление тортов/Роспись/painting5.avif";
import painting6 from "../img/item/Оформление тортов/Роспись/painting6.avif";
import painting7 from "../img/item/Оформление тортов/Роспись/painting7.avif";
import painting8 from "../img/item/Оформление тортов/Роспись/painting8.avif";
import painting9 from "../img/item/Оформление тортов/Роспись/painting9.avif";
import painting10 from "../img/item/Оформление тортов/Роспись/painting10.avif";
import painting11 from "../img/item/Оформление тортов/Роспись/painting11.avif";
import painting12 from "../img/item/Оформление тортов/Роспись/painting12.avif";
import painting13 from "../img/item/Оформление тортов/Роспись/painting13.avif";
import painting14 from "../img/item/Оформление тортов/Роспись/painting14.avif";
import painting15 from "../img/item/Оформление тортов/Роспись/painting15.avif";
import painting16 from "../img/item/Оформление тортов/Роспись/painting16.avif";
import painting17 from "../img/item/Оформление тортов/Роспись/painting17.avif";
import painting18 from "../img/item/Оформление тортов/Роспись/painting18.avif";
import painting19 from "../img/item/Оформление тортов/Роспись/painting19.avif";
import painting20 from "../img/item/Оформление тортов/Роспись/painting20.avif";
import painting21 from "../img/item/Оформление тортов/Роспись/painting21.avif";
import painting22 from "../img/item/Оформление тортов/Роспись/painting22.avif";
import painting23 from "../img/item/Оформление тортов/Роспись/painting23.avif";
import painting24 from "../img/item/Оформление тортов/Роспись/painting24.avif";
import painting25 from "../img/item/Оформление тортов/Роспись/painting25.avif";
import painting26 from "../img/item/Оформление тортов/Роспись/painting26.avif";
import painting27 from "../img/item/Оформление тортов/Роспись/painting27.avif";
import painting28 from "../img/item/Оформление тортов/Роспись/painting28.avif";
import painting29 from "../img/item/Оформление тортов/Роспись/painting29.avif";
import painting30 from "../img/item/Оформление тортов/Роспись/painting30.avif";
import painting31 from "../img/item/Оформление тортов/Роспись/painting31.avif";
import painting32 from "../img/item/Оформление тортов/Роспись/painting32.avif";


export const paintingDecor = [
  {
    name: `Роспись торта`,
    descDecor: 'поз. 1',
    img: painting1,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 2',
    img: painting2,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 3',
    img: painting3,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 4',
    img: painting4,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 5',
    img: painting5,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 6',
    img: painting6,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 7',
    img: painting7,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 8',
    img: painting8,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 9',
    img: painting9,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 10',
    img: painting10,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 11',
    img: painting11,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 12',
    img: painting12,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 13',
    img: painting13,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 14',
    img: painting14,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 15',
    img: painting15,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 1',
    img: painting16,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 16',
    img: painting1,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 17',
    img: painting17,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 18',
    img: painting18,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 19',
    img: painting19,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 20',
    img: painting20,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 21',
    img: painting21,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 22',
    img: painting22,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 23',
    img: painting23,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 24',
    img: painting24,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 25',
    img: painting25,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 26',
    img: painting26,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 27',
    img: painting27,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 28',
    img: painting28,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 29',
    img: painting29,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 30',
    img: painting30,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 31',
    img: painting31,
  },
  {
    name: `Роспись торта`,
    descDecor: 'поз. 32',
    img: painting32,
  },
];