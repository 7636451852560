import {
  ganash_Beilis,
  ganash_Mokko,
  klubnika_Bazelik,
  laim_mjata,
  sol_karamel,
  fistashka_malina,
  black_smorodina,
  mango_marakuja_mak,
} from "./img_import";

export const makaronsList = {
  meta: {
    title: "ДжоКонди | Макаронсы",
    description: "Макаронсы",
    keywords:
      "ганаш, клубника-базилик, лайм-мята, солёная карамель, фисташка-малина, чёрная смородина, манго-маракуйя",
  },
  productList: [
    {
      id: "mak1",
      groupProduct: "Макаронс",
      nameProduct: '"Ганаш Бейлис"',
      img: ganash_Beilis,
      price: 130,
      unit: "шт.",
      minCount: 6, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mak2",
      groupProduct: "Макаронс",
      nameProduct: '"Ганаш Мокко"',
      img: ganash_Mokko,
      price: 130,
      unit: "шт.",
      minCount: 6, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mak3",
      groupProduct: "Макаронс",
      nameProduct: '"Клубника-Базилик"',
      img: klubnika_Bazelik,
      price: 130,
      unit: "шт.",
      minCount: 6, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mak4",
      groupProduct: "Макаронс",
      nameProduct: '"Лайм-Мята"',
      img: laim_mjata,
      price: 130,
      unit: "шт.",
      minCount: 6, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mak5",
      groupProduct: "Макаронс",
      nameProduct: '"Солёная карамель"',
      img: sol_karamel,
      price: 130,
      unit: "шт.",
      minCount: 6, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mak6",
      groupProduct: "Макаронс",
      nameProduct: '"Фисташка-Малина"',
      img: fistashka_malina,
      price: 130,
      unit: "шт.",
      minCount: 6, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mak7",
      groupProduct: "Макаронс",
      nameProduct: '"Чёрная смородина"',
      img: black_smorodina,
      price: 130,
      unit: "шт.",
      minCount: 6, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mak8",
      groupProduct: "Макаронс",
      nameProduct: '"Манго-Маракуйя"',
      img: mango_marakuja_mak,
      price: 130,
      unit: "шт.",
      minCount: 6, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
  ],
};
